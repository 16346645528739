import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { createTeams } from "../redux/teamsSlice";

export const Team = (props) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(createTeams({
        path: '/get/team/member/list',
        method: 'GET',
        params: {},
        data: {},
        options: {},
        headersObject: {}
    }));
}, [dispatch]);

const { TeamsList } = useAppSelector(state => state.teamsSlice);

console.log(TeamsList)
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
          Our team of skilled professionals is dedicated to delivering exceptional craftsmanship and personalized service in every project we undertake.
          </p>
        </div>
        <div id="row">
          {props.data
            ? TeamsList[0]?.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={`http://3.110.117.182/stores/`+d.avatar} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.designation}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
