import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { createService } from "../redux/ServiceSlice";

export const Services = (props) => {

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(createService({
        path: '/fetch/service',
        method: 'GET',
        params: {},
        data: {},
        options: {},
        headersObject: {}
    }));
}, [dispatch]);

const { servicesList } = useAppSelector(state => state.ServiceSlice);
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
          We offer expert false ceiling solutions that combine style, functionality, and quality craftsmanship for both homes and offices.
          </p>
        </div>
        <div className="row">
          {props.data
            ? servicesList[0]?.map((d, i) => (
                <div key={`${props.data[i].name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={props.data[i].icon}></i>
                  <div className="service-desc">
                    <h3>{d.heading}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
