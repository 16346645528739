import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAPI } from '../services/APIServices';

const initialState = {
    servicesList: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

// Async thunk to insert about data
export const createService = createAsyncThunk(
    'service/createService',
    async ({ path, method, params, data, options, headersObject }, thunkAPI) => {
        try {
            const response = await callAPI(path, params, method, data, options, headersObject);
            return response.data.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Async thunk to fetch about data
export const fetchService = createAsyncThunk(
    'service/fetchService',
    async ({ path, method, params, data, options, headersObject }, thunkAPI) => {
        try {
            const response = await callAPI(path, params, method, data, options, headersObject);
            return response.data.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            // Handle createAbout cases
            .addCase(createService.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.servicesList = [...state.servicesList, action.payload];
                state.isSuccess = true;
            })
            .addCase(createService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            // Handle fetchAbout cases
            .addCase(fetchService.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.servicesList = action.payload;
                state.isSuccess = true;
            })
            .addCase(fetchService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    }
});

export const { reset } = serviceSlice.actions;
export default serviceSlice.reducer;
