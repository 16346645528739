import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAPI } from '../services/APIServices';

const initialState = {
    featuresLists: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

// Adjust the async thunk to accept a single argument with destructured properties
export const getFeatureslist = createAsyncThunk(
    'featuresLists',
    async ({ path, method, params, data, options, headersObject }, thunkAPI) => {
        try {
            const response = await callAPI(path, params, method, data, options, headersObject);
            return response.data.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const featuresSlice = createSlice({
    name: 'featuresLists',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFeatureslist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFeatureslist.fulfilled, (state, action) => {
                state.isLoading = false;
                state.featuresLists = action.payload; // Fixing the state update
                state.isSuccess = true;
            })
            .addCase(getFeatureslist.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    }
});

export const { reset } = featuresSlice.actions;
export default featuresSlice.reducer;
