import axios from "axios";
const BASE_PATH = 'http://3.110.117.182/api';

const serializerConfig = {
    arrayFormat: 'brackets',
    encode: false,
};

const callAPI = (path, params, method, data = null, options = {}, headersObj = {}) => {
    console.log(path, method);
    const API_ROOT = `${BASE_PATH}${path}`;
    const headers = {
        'Content-Type': 'application/json',
        ...headersObj
    };

    return axios({
        method,  // Changed 'methods' to 'method' (method is singular)
        url: API_ROOT,
        params,
        data: JSON.stringify(data),
        withCredentials: true,
        headers,
        ...options
    }).catch(error => {
        console.error('API call failed:', error);
        throw error; // Re-throw the error after logging
    });
};

export { callAPI };
