import React, { useState } from "react";
import ModelLogin from "./model";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { BsXLg } from "react-icons/bs";
import validator from 'validator';
import Spinner from 'react-bootstrap/Spinner';
import { Alert, Box, CircularProgress, Snackbar } from "@mui/material";
import { callAPI } from "../services/APIServices";
export const Navigation = (props) => {
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src={props.data} className="app-logo" />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            {/* <li >
              <a href="#login" className="page-scroll">
                <LoginModel />
              </a>
            </li> */}
          </ul>
         
        </div>
      </div>
    </nav>
  );
};

function LoginModel() {
  const [show, setShow] = useState(false);


  const handleShowModel = () => setShow(true);

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("off");
  const [validated, setValidated] = useState({email:false, password:false});
  const [emailMessage, setEmailMessage] = useState('Email/Username is required!')
  const [disabledStatus, setDisabledStatus]= useState(false)
  const [openMessage, setOpenMessage] = React.useState(false);

  // const handleSubmit =async () => {

  //   if (username.length == 0 && password.length == 0) {
  //     setValidated({password:true,email:true});    
  //     setEmailMessage(`Please enter valid email.`)
  //   }
  //   if(!validator.isEmail(username)){
  //     setValidated({password:password.length==0?true:false, email:true });
  //     if(username.length == 0 ){
  //     setEmailMessage('Email/Username is required!')
  //     }else{
  //       setEmailMessage(`Please enter valid email.`)
  //     } 
  //   }
  //   if(validator.isEmail(username) && password.length !=0 && username !=0){
     
  //     setDisabledStatus(true)
  //   const {data} =    await  callAPI("/admin/login",{},"POST",{email:username, password},{},{})
  //      if(data.status== true){
      
  //       setShow(false)
  //       setDisabledStatus(false)
  //       setOpenMessage(true)
  //      }
  //   }
  //  };
  const handleSubmit = async () => {
    if (username.length == 0 && password.length == 0) {
      setValidated({ password: true, email: true });
      setEmailMessage(`Please enter valid email.`);
      return; // Stop the function here if fields are empty
    }
  
    if (!validator.isEmail(username)) {
      setValidated({ password: password.length == 0 ? true : false, email: true });
      if (username.length == 0) {
        setEmailMessage('Email/Username is required!');
      } else {
        setEmailMessage(`Please enter valid email.`);
      }
      return; // Stop the function here if email is invalid
    }
  
    if (validator.isEmail(username) && password.length != 0 && username != 0) {
      setDisabledStatus(true);
      try {
        const { data } = await callAPI("/admin/login", {}, "POST", { email: username, password }, {}, {});
        if (data.status == true) {
          setShow(false);
          setOpenMessage(true); // Show the Snackbar only on successful API response
        }
      } catch (error) {
        console.error("API call failed:", error);
        // Handle error case if needed
      } finally {
        setDisabledStatus(false);
      }
    }
  };
  
   const handleClose = () =>{
     setShow(false)
     setDisabledStatus(false)
    };
    const handleSnackerClose = ()=>{
      setOpenMessage(false)
    }


  return (
    <>
      <span variant="primary" onClick={handleShowModel}>
        Login
      </span>
      <Modal show={show} onHide={handleClose} animation={false}>
      <Box sx={{ display: 'flex' }}>
        {disabledStatus && <CircularProgress />}
     
    </Box>
  
    
        <Modal.Header className="login-model-headers">
          <Modal.Title>Login </Modal.Title>
          <Modal.Title className="close-title">
            <BsXLg onClick={handleClose} />{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3 mt-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Please enter the username/email"
                autoFocus
                autoComplete="off"
                onChange={(e) =>{ setUserName(e.target.value); setValidated({...validated, email:false})}}
                required
                disabled={disabledStatus}
              />
              {validated.email   ? (
                <Form.Control.Feedback type="error" color="red">
                  {" "}
                  <span className="error-color">{emailMessage}</span>
                
                </Form.Control.Feedback>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group
              className="mb-3 mt-3 password-form"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Password</Form.Label>
              <Form.Control
             
                type="password"
                placeholder="*******"
                autoFocus
                onChange={(e) =>{ setPassword(e.target.value); setValidated({...validated, password:false})}}
                disabled={disabledStatus}
              />
              {validated.password  ? (
                <Form.Control.Feedback type="error" color="red">
                  {" "}
                  <span className="error-color">Password is required!.</span>
                
                </Form.Control.Feedback>
              ) : (
                ""
              )}
            </Form.Group>
            {["checkbox"].map((type) => (
              <div key={type} className="mb-3">
                <Form.Check
                  type={type}
                  id={`check-api-${type}`}
                  className="password-form checkbox-2"
                >
                  <Form.Check.Input
                    type={type}
                    isValid
                    onChange={(e) => setUserType(e.target.value)}
                    disabled={disabledStatus}
                  />
                  <span className="checkbox-text">
                    Login as Secondary User?
                  </span>
                </Form.Check>
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={disabledStatus}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={disabledStatus}>
          
            Login
          </Button>
        </Modal.Footer>
        {/* {openMessage && <Snackbar open={openMessage} autoHideDuration={6000} onClose={handleSnackerClose}>
  <Alert
    onClose={handleSnackerClose}
    severity="success"
    variant="filled"
    sx={{ width: '100%' }}
  >
    This is a success Alert inside a Snackbar!
  </Alert>
</Snackbar>} */}
      </Modal>
    </>
  );
}

export default LoginModel;
