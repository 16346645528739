import { configureStore } from '@reduxjs/toolkit';
import bannersSlice from '../redux/bannersSlice';
import featuresSlice from '../redux/featuresSlice';
import aboutSlice from '../redux/aboutSlice';
import ServiceSlice from '../redux/ServiceSlice';
import testimonialsSlice from '../redux/testimonialsSlice';
import galleriesSlice from '../redux/galleriesSlice';
import teamsSlice from '../redux/teamsSlice';

const store = configureStore({
    reducer: {
        banners: bannersSlice,
        featuresSlice:featuresSlice, // Enure that the key matches the slice name 'banners'
        aboutSlice:aboutSlice,
        ServiceSlice:ServiceSlice,
        testimonialsSlice:testimonialsSlice,
        galleriesSlice:galleriesSlice,
        teamsSlice:teamsSlice
    },
});

export default store;