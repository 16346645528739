import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { createAbout } from "../redux/aboutSlice";

export const About = (props) => {
  const [text, setText]= useState('')
  const [image, setImage]= useState('')
  const [why, setWhy] = useState([])
  const [why2, setWhy2] = useState([])
  const dispatch = useAppDispatch();

  React.useEffect(() => {
      dispatch(createAbout({
          path: '/fetch/about',
          method: 'GET',
          params: {},
          data: {},
          options: {},
          headersObject: {}
      }));
  }, [dispatch]);

  const { aboutData} = useAppSelector(state => state.aboutSlice);

  
  useEffect(()=>{
    
    setText(aboutData[0]?.text)
    setImage(aboutData[0]?.url)
    if(aboutData[0]?.url && aboutData[0]?.text){
      const {text, url, _id,__v,...ans} = aboutData[0]
     const whys =Object.values(ans)
     const firstArray = whys.slice(0, 4);
     setWhy(firstArray)
     // Create the second array with the last 4 elements
     const secondArray = whys.slice(4, 8);
     setWhy2(secondArray)
    }
  },[aboutData.length])
 

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={image} className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{text ? text : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? why?.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? why2?.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
