import { Avatar } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { createTestimonials } from "../redux/testimonialsSlice";

export const Testimonials = (props) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(createTestimonials({
        path: '/get/feedbacks',
        method: 'GET',
        params: {},
        data: {},
        options: {},
        headersObject: {}
    }));
}, [dispatch]);

const { TestimonialsList } = useAppSelector(state => state.testimonialsSlice);


  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  function stringAvatar(name) {
  
    if (!name) {
      return {
        sx: {
          bgcolor: "#ccc", // default background color
        },
        children: "?", // default avatar text
      };
    }
  
    const nameParts = name.split(' ');
    const initials = nameParts.length === 1 
      ? `${nameParts[0][0]}${nameParts[0][0]}` 
      : `${nameParts[0][0]}${nameParts[1][0]}`;
  
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  }
  
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {true?TestimonialsList[0]?.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <Avatar {...stringAvatar(d?.name)}/>
                      {/* <img src={d.img} alt="" />{" "} */}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
