import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getBannerslist } from "../redux/bannersSlice";

export const Header = (props) => {
  const [backgroundImage, setBackgroundImage] = React.useState('/intro1.jpg');
  const [addClass, setAddClass] = React.useState('intro')
  const [banners, setBanners] = React.useState([])
  const [baseURL ,setBaseURL]= React.useState('../img')
  // const banners = ["../img/intro1.jpg", "../img/intro3.jpg","../img/intro4.jpg"];

const random = Math.floor(Math.random() * banners.length);
const dispatch = useAppDispatch();
// dispatch(getBannerslist('/banners/list','GET',{},{},{},{}))

// const bannersLists = useAppSelector((state)=>state.bannersSlice)
// console.log(bannersList)
// const
React.useEffect(() => {
  dispatch(getBannerslist({
      path: '/banners/list',
      method: 'GET',
      params: {},
      data: {},
      options: {},
      headersObject: {}
  }));
}, [dispatch]);
const {bannersList,  isLoading, isError, isSuccess, message } = useAppSelector(state => state.banners);

const banners1 = bannersList.map((item)=>item.url)

React.useEffect(()=>{

  let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % banners1.length; // Cycle through images
     
         if(bannersList.length !==0){
          setBaseURL("http://3.110.117.182/stores/")
          setBackgroundImage(banners1[index]);
         }
       
      
    
    }, 4000); // 4000 milliseconds = 4 seconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
},[banners1])

const backgroundStyle = {
  width: '100%',
  height: '100vh',
  backgroundImage: `url(${baseURL}${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

  return (
    <header id="header">
      <div className={addClass} style={backgroundStyle}>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h2>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h2>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
