import { useAppDispatch, useAppSelector } from "../app/hooks";
import { createGalleries } from "../redux/galleriesSlice";
import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(createGalleries({
        path: '/fetch/galleries',
        method: 'GET',
        params: {},
        data: {},
        options: {},
        headersObject: {}
    }));
}, [dispatch]);

const { GalleriesList } = useAppSelector(state => state.galleriesSlice);

console.log(GalleriesList)
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
          Explore our previous work gallery to see how we've transformed spaces with our expert false ceiling solutions.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? GalleriesList[0]?.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={`http://3.110.117.182/stores/`+d.url}
                      smallImage={`http://3.110.117.182/stores/`+d.url}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
