import { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { callAPI } from "../services/APIServices";
import { Box, CircularProgress, Snackbar } from "@mui/material";
// import { SMTPClient } from 'emailjs';

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const[contactsData, setContractsData] = useState({})
  
  const [open, setOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('')
  const [loader, setLoader] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  const handleClose = () => {
       setOpen(false)
    }

  useEffect( async ()=>{
    const {data} =  await  callAPI('/get/contacts', {}, 'Get',{},{},{})
   setContractsData(data.data)
  },[])
  
  const handleSubmit = async (e) => {
    setLoader(true)
    e.preventDefault();

  const {data} =  await  callAPI('/receive/inquiries', {}, 'POST',{name, email,text:message},{},{})

  
    if(data.status){
      setLoader(false)
      setOpen(true)
      setSuccessMessage(data.message)
      clearState()
    }
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    
    
  };
  return (
    <div>
       <Box sx={{ width: 500 }}>
      <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'right' }}
  open={open}
  autoHideDuration={5000}
  onClose={handleClose}
  message={successMessage}/></Box>
   <Box sx={{ display: 'flex' }}>
  {loader && <CircularProgress />}
     
    </Box>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        value={name}
                        disabled={loader}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                        disabled={loader}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                    disabled={loader}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg" disabled={loader}>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {contactsData?.address1 ? `${contactsData?.address1}, ${contactsData?.address2}, ${contactsData?.city}, ${contactsData?.state},${contactsData?.pin}` : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {contactsData?.address1 ? contactsData?.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-whatsapp"></i> Whatsapp
                </span>{" "}
                {contactsData?.address1 ?contactsData?.whatsapp : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {contactsData?.address1 ? contactsData?.email : "loading"}
              </p>
            </div>
          </div>
        
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 & 2025 Gaurs Enterprises. Design by{" "}
            <a href="#" rel="nofollow">
              Yogesh Sharma
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
