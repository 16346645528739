import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callAPI } from '../services/APIServices';

const initialState = {
    GalleriesList: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

// Async thunk to insert about data
export const createGalleries = createAsyncThunk(
    'Galleries/createGalleries',
    async ({ path, method, params, data, options, headersObject }, thunkAPI) => {
        try {
            const response = await callAPI(path, params, method, data, options, headersObject);
            return response.data.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Async thunk to fetch about data
export const fetchGalleries = createAsyncThunk(
    'Galleries/fetchGalleries',  // Changed action type here
    async ({ path, method, params, data, options, headersObject }, thunkAPI) => {
        try {
            const response = await callAPI(path, params, method, data, options, headersObject);
            return response.data.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const galleriesSlice = createSlice({
    name: 'Galleries',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            // Handle createTestimonials cases
            .addCase(createGalleries.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createGalleries.fulfilled, (state, action) => {
                state.isLoading = false;
                state.GalleriesList = [...state.GalleriesList, action.payload];
                state.isSuccess = true;
            })
            .addCase(createGalleries.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            // Handle fetchTestimonials cases
            .addCase(fetchGalleries.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchGalleries.fulfilled, (state, action) => {
                state.isLoading = false;
                state.GalleriesList = action.payload;
                state.isSuccess = true;
            })
            .addCase(fetchGalleries.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    }
});

export const { reset } = galleriesSlice.actions;
export default galleriesSlice.reducer;
