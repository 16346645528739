import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getFeatureslist } from "../redux/featuresSlice";

export const Features = (props) => {

  const dispatch = useAppDispatch();

  React.useEffect(() => {
      dispatch(getFeatureslist({
          path: '/fetch/features',
          method: 'GET',
          params: {},
          data: {},
          options: {},
          headersObject: {}
      }));
  }, [dispatch]);

  const { featuresLists, isLoading, isError, isSuccess, message } = useAppSelector(state => state.featuresSlice);

 
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>
        <div className="row">
          {props.data
            ? featuresLists.map((d, i) => (
                <div key={`${d.heading}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={props.data[i].icon}></i>
                  <h3>{d.heading}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
